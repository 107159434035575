/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is photovoltaik page
 */

import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { IProduct, Extras } from 'bsh-calculator';
import { t } from 'i18next';

import ContentArea from '../components/theme/ContentArea';
import ImageButton from '../components/elements/ImageButton';
import i18n from '../i18n';
import CheckboxComponent, { CheckboxOption } from '../components/forms/Checkbox';
import Select from '../components/forms/Select';
import InputText, { InputRefType } from '../components/forms/InputText';
import photovoltaikLogo from '../assets/images/photovoltaik.jpg';
import useStore from '../hooks/useStore';
import ModuleAreaSection from '../components/photovoltaik/ModuleAreaSection';
import Button from '../components/buttons/Button';
import Bottom from '../components/theme/Bottom';
import { ModuleAreaForm } from '../interfaces/IComponents';
import Tooltip from '../components/elements/Tooltip';
import CheckboxWithInput from '../components/forms/CheckboxWithInput';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import config, { storageBrands } from '../config';
import Validation from '../lib/Validation';
import Slider from '../components/forms/Slider';
import Utils from '../lib/Utils';
// import SelfSufficienceSection from '../components/photovoltaik/SelfSufficienceSection/SelfSufficienceSection';

const PhotovoltaikPage: React.FC = observer(() => {
  const storageSelectRef = useRef<InputRefType>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { cacheStore, photovoltaikStore, dataStore } = useStore();
  const {
    moduleAreaForms,
    modulTypeId,
    photovoltaikExtrasForm,
    summaryModuleAreaForm,
    selfSufficienceForm,
    storagesId,
    imageRoofPlan,
    roofPlanImageInfo,
    storageBrand,
  } = photovoltaikStore;
  const {
    newCounterBox,
    moveSat,
    withoutZaehlerhelden,
    privatCounterBox,
    privatCounterBoxNumber,
    moveRoofSteps,
    fem111SmartCounter,
    fem111SmartCounterNumber,
    femAppPowerHeat,
    enerPlug,
    enerPlugNumber,
    enerPlugSetNumber,
    drohn,
    birdProtection,
    senecHeat,
  } = photovoltaikExtrasForm;
  const { summaryNumberModules, pvPlannedSize, averageYield, inverterPower } = summaryModuleAreaForm;

  const modulType = cacheStore.getProductById(modulTypeId ?? '', '', true);
  const storages = cacheStore.getProductById(storagesId ?? '', '', true);

  const cacheStorages = cacheStore.getProductsByGroupId('storage') ?? [];

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { goToNextPage, goToPreviousPage } = useBSHNavigation();
  const extraOptions = [
    { id: 'newCounterBox', value: i18n.t('photovoltaikPage.newCounterBox'), checked: newCounterBox ?? false },
    { id: 'moveSat', value: i18n.t('photovoltaikPage.moveSat'), checked: moveSat ?? false },
    {
      id: 'withoutZaehlerhelden',
      value: i18n.t('photovoltaikPage.withoutCounterHeroes'),
      checked: withoutZaehlerhelden ?? false,
      // TODO temporary solution
      disabled: storageBrand === 'senec',
    },
    { id: 'drohn', value: i18n.t('photovoltaikPage.checkboxDrohn'), checked: drohn ?? false },
  ];

  // handle selectinon of module
  const handleModuleClick = (modul: Nullable<IProduct>) => () => photovoltaikStore.setModulTypeId(modul?.serviceId ?? null);

  // set extra section data form
  const setPhotovoltaikExtrasData = (newForm: Extras) => {
    photovoltaikStore.setPhotovoltaikExtrasForm(newForm);
  };

  // handle change of extra options
  const handleExtrasChange = (newOpts: CheckboxOption[]) => {
    const newCounter = newOpts.find((opt) => opt.id === 'newCounterBox');
    const newMoveSat = newOpts.find((opt) => opt.id === 'moveSat');
    const newWithoutZaehlerhelden = newOpts.find((opt) => opt.id === 'withoutZaehlerhelden');
    const newDrohn = newOpts.find((opt) => opt.id === 'drohn');

    setPhotovoltaikExtrasData({
      ...photovoltaikExtrasForm,
      newCounterBox: newCounter?.checked ?? false,
      moveSat: newMoveSat?.checked ?? false,
      withoutZaehlerhelden: newWithoutZaehlerhelden?.checked ?? false,
      drohn: newDrohn?.checked ?? false,
    });
  };

  // handle adding new module area form
  const handleAddModuleClick = () => {
    photovoltaikStore.addEmptyModuleAreaForm();
  };

  // handle close click on module area form
  const handleModuleAreaClose = (id: string) => () => {
    if (moduleAreaForms.length === 1) {
      return;
    }

    photovoltaikStore.removeModuleArea(id);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const setImageRoofPlan = (file: Nullable<File>) => {
    photovoltaikStore.setImageRoofPlan(file ? URL.createObjectURL(file) : null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const fileSizeInMB = file.size / 1024 / 1024;
      const fileType = file.type;

      if (fileSizeInMB > 2) {
        setErrorMessage(t('basicDataPage.fileLimit'));
        setImageRoofPlan(null);

        return;
      }

      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        setErrorMessage(t('basicDataPage.fileFormat'));
        setImageRoofPlan(null);

        return;
      }

      setImageRoofPlan(file);
      photovoltaikStore.setRoofPlanImageFile(file);
      setErrorMessage('');
    }
  };

  const handleRemoveImage = () => {
    setImageRoofPlan(null);
    photovoltaikStore.setRoofPlanImageFile(null);
  };

  const handleBrandChange = (brandId: string) => {
    photovoltaikStore.setStorageBrand(brandId);
    photovoltaikStore.setStoragesId(null);
    photovoltaikStore.setModulTypeId(null);

    photovoltaikStore.setPhotovoltaikExtrasForm({
      ...photovoltaikExtrasForm,
      // TODO temporary solution
      withoutZaehlerhelden: brandId === 'senec',
      senecHeat: brandId === 'senec' ? senecHeat : false,
      enerPlug: brandId === 'enerFusion' ? enerPlug : false,
      enerPlugNumber: brandId === 'enerFusion' ? enerPlugNumber : undefined,
      enerPlugSetNumber: brandId === 'enerFusion' ? enerPlugSetNumber : undefined,
    });
  };

  // const handleSelfSufficienceChange = (options: CheckboxOption[]) => {
  //   const manualOption = options.find((o) => o.id === 'manual');

  //   photovoltaikStore.setSelfSufficienceForm({
  //     ...selfSufficienceForm,
  //     selfSufficienceManually: manualOption?.checked ?? true,
  //   });
  // };

  const modules = cacheStore.getSelectionModules(storageBrand);
  const storageOptions = cacheStore.getStorageSelectOptions(storageBrand);

  // visible if storage brand is Fenecon
  const femVisible = storages?.brandId === '2' || false;

  const femAppPowerHeatOption = config.femAppPowerHeatOptions.find((opt) => opt.id === femAppPowerHeat);

  const isValidated =
    moduleAreaForms.map((form) => form.getFormValidation?.() ?? true).reduce((acc, validated) => (validated ? acc : validated), true) &&
    !!storages &&
    !!modulType;

  const options = [{ id: '', label: i18n.t('photovoltaikPage.noSelection') }, ...storageOptions];

  const imageName = roofPlanImageInfo
    ? `${roofPlanImageInfo.name} (${(roofPlanImageInfo.size / 1024).toFixed(2)} kB)`
    : imageRoofPlan ?? '';

  useEffect(() => {
    const refValue = storageSelectRef.current;

    if (refValue) {
      const validObj = refValue.inputValidate(storages?.name ?? '');

      if (!validObj.validated) {
        refValue.setValidation(validObj);
      }
    }
  }, [storages]);

  return (
    <ContentArea>
      <div className="content-main">
        <div className="photovoltaik-title-row">
          <img src={photovoltaikLogo} className="title-image" alt="photovoltaik" />
          <h2 className="photovoltaik-title">{i18n.t('forms.photovoltaics').toUpperCase()}</h2>
        </div>
        <h2 className="photovoltaik-subtitle">{i18n.t('photovoltaikPage.brandSelection')}</h2>
        <div className="modulart-buttons-row" data-testid="brands-container">
          {storageBrands.map((brand) => (
            <ImageButton
              key={brand.id}
              imageSrc={brand.image}
              isActive={storageBrand === brand?.serviceId.toString()}
              onClick={() => handleBrandChange(brand.serviceId.toString())}
              fullImage
              className="module-button"
            />
          ))}
        </div>
        {storageBrand && (
          <>
            <h2 className="photovoltaik-subtitle">{i18n.t('photovoltaikPage.module')}</h2>
            <div className="modulart-buttons-row" data-testid="modules-container">
              {modules?.map((module) => (
                <ImageButton
                  key={module.id}
                  label={module.name}
                  imageSrc={module?.imageCalculator ?? undefined}
                  isActive={modulType?.id === module.id}
                  onClick={handleModuleClick(module)}
                  className="module-button"
                />
              ))}
            </div>
            <h2 className="photovoltaik-subtitle">{i18n.t('photovoltaikPage.storage')}</h2>
            <Select
              ref={storageSelectRef}
              value={storages ? { label: storages.name, id: storages.id } : undefined}
              title={i18n.t('photovoltaikPage.storageSelection')}
              options={options}
              placeholder={i18n.t('photovoltaikPage.noSelection')}
              wrapperClassName="storage-select"
              testId="storage-select"
              validateFunc={(p: string) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'))}
              onSuggestionSelected={(option) => {
                const newStorage = cacheStorages.find((stg) => stg.id === option.id);

                // Storage brand === 'Fenecon'
                if (storages?.brandId === '2' && newStorage?.brandId !== '2') {
                  setPhotovoltaikExtrasData({
                    ...photovoltaikExtrasForm,
                    fem111SmartCounter: false,
                    femAppPowerHeat: undefined,
                    fem111SmartCounterNumber: 0,
                  });
                }

                photovoltaikStore.setStoragesId(newStorage?.serviceId ?? null);
                dataStore.setProductsBasedOnStorage(newStorage?.serviceId ?? null);
              }}
            />
          </>
        )}
        <div className="storage-inputs-row">
          <InputText
            value={summaryNumberModules}
            type="number"
            className="storage-input"
            title={i18n.t('photovoltaikPage.numberModules')}
            isDisabled
            testId="summary-number-modules"
          />
          <InputText
            value={pvPlannedSize}
            type="number"
            className="storage-input"
            title={i18n.t('photovoltaikPage.plannedSize')}
            suffix="kWp"
            isDisabled
            testId="planned-size"
          />
          <InputText
            value={averageYield}
            type="number"
            className="storage-input"
            title={i18n.t('photovoltaikPage.averageYield')}
            suffix="kWh/Jahr"
            isDisabled
          />
          <InputText
            value={inverterPower}
            type="number"
            className="storage-input"
            title={i18n.t('photovoltaikPage.inverterPower')}
            suffix="KW"
            isDisabled
          />
        </div>
        <h2 className="photovoltaik-subtitle">{i18n.t('photovoltaikPage.moduleSurfaces')}</h2>
        {moduleAreaForms.map((form, index) => {
          const setForm = (newForm: ModuleAreaForm) => photovoltaikStore.setModuleAreaForm(newForm.id, newForm);

          return (
            <ModuleAreaSection
              // eslint-disable-next-line react/no-array-index-key
              key={form.id}
              index={index}
              moduleForm={form}
              setModuleForm={setForm}
              onCloseClick={handleModuleAreaClose(form.id)}
            />
          );
        })}
        <Tooltip text={i18n.t('photovoltaikPage.addNewModuleArea')} isShow={tooltipVisible} position="right">
          <Button
            text={i18n.t('buttons.add')}
            onClick={handleAddModuleClick}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            testId="add-module-button"
          />
        </Tooltip>
        <h2 className="photovoltaik-subtitle">{i18n.t('photovoltaikPage.extraOptions')}</h2>
        <div className="extra-options-row">
          <CheckboxComponent options={extraOptions} onChange={handleExtrasChange} />
        </div>
        <div className="extra-options-row">
          <CheckboxWithInput
            label={i18n.t('photovoltaikPage.privateCounterBox')}
            checked={privatCounterBox}
            showInput
            onChange={() => {
              setPhotovoltaikExtrasData({
                ...photovoltaikExtrasForm,
                privatCounterBox: !privatCounterBox,
                privatCounterBoxNumber: !privatCounterBox ? 1 : 0,
              });
            }}
            inputValues={[{ label: i18n.t('common.quantity'), value: privatCounterBoxNumber }]}
            setValues={(values) => {
              setPhotovoltaikExtrasData({
                ...photovoltaikExtrasForm,
                privatCounterBoxNumber: Number(values[0]?.value),
              });
            }}
          />
          <CheckboxWithInput
            label={i18n.t('photovoltaikPage.moveRoofSteps')}
            checked={moveRoofSteps}
            onChange={() => {
              setPhotovoltaikExtrasData({
                ...photovoltaikExtrasForm,
                moveRoofSteps: !moveRoofSteps,
              });
            }}
          />
          {femVisible && (
            <CheckboxWithInput
              label={i18n.t('photovoltaikPage.fem111SmartCounter')}
              checked={fem111SmartCounter}
              showInput
              onChange={() => {
                setPhotovoltaikExtrasData({
                  ...photovoltaikExtrasForm,
                  fem111SmartCounter: !fem111SmartCounter,
                  fem111SmartCounterNumber: !fem111SmartCounter ? 1 : undefined,
                });
              }}
              inputValues={[{ label: i18n.t('common.quantity'), value: fem111SmartCounterNumber }]}
              setValues={(values) => {
                setPhotovoltaikExtrasData({
                  ...photovoltaikExtrasForm,
                  fem111SmartCounterNumber: values[0]?.value as number,
                });
              }}
            />
          )}
          {storageBrand === 'enerFusion' && (
            <CheckboxWithInput
              label={i18n.t('photovoltaikPage.checkboxEnerPlug')}
              checked={enerPlug}
              showInput
              testId="enerPlug-checkbox"
              onChange={() => {
                setPhotovoltaikExtrasData({
                  ...photovoltaikExtrasForm,
                  enerPlug: !enerPlug,
                  enerPlugNumber: !enerPlug ? 1 : undefined,
                  enerPlugSetNumber: !enerPlug ? 1 : undefined,
                });
              }}
              inputValues={[
                { label: `${i18n.t('common.quantity')} Ener.Plug`, value: enerPlugNumber },
                { label: `${i18n.t('common.quantity')} Ener.Plug.Set`, value: enerPlugSetNumber },
              ]}
              setValues={(values) => {
                setPhotovoltaikExtrasData({
                  ...photovoltaikExtrasForm,
                  enerPlugNumber: Number(values[0]?.value),
                  enerPlugSetNumber: Number(values[1]?.value),
                });
              }}
            />
          )}
          <CheckboxComponent
            options={[{ id: 'birdProtection', checked: birdProtection ?? false, value: i18n.t('photovoltaikPage.birdProtection') }]}
            onChange={(opts) => {
              const birdProtectionOption = opts.find((opt) => opt.id === 'birdProtection');

              setPhotovoltaikExtrasData({
                ...photovoltaikExtrasForm,
                birdProtection: birdProtectionOption?.checked ?? false,
              });
            }}
          />
          {storageBrand === 'senec' && (
            <CheckboxComponent
              options={[{ id: 'senecHeat', checked: senecHeat ?? false, value: i18n.t('photovoltaikPage.senecHeat') }]}
              onChange={(opts) => {
                const senecHeatOption = opts.find((opt) => opt.id === 'senecHeat');

                setPhotovoltaikExtrasData({
                  ...photovoltaikExtrasForm,
                  senecHeat: senecHeatOption?.checked ?? false,
                });
              }}
            />
          )}
        </div>
        {/* <div className="extra-options-row">

        </div> */}
        <div className="extra-options-row">
          {femVisible && (
            <Select
              title={i18n.t('photovoltaikPage.femsPowerHeat')}
              options={config.femAppPowerHeatOptions}
              value={femAppPowerHeatOption}
              onSuggestionSelected={(val) => {
                setPhotovoltaikExtrasData({
                  ...photovoltaikExtrasForm,
                  femAppPowerHeat: val.id.toString(),
                });
              }}
              wrapperClassName="extra-select"
            />
          )}
        </div>
        <h2 className="photovoltaik-subtitle">AUTARKIEGRAD</h2>
        {/* <div className="storages-selection-wrapper">
          <CheckboxComponent
            options={[
              {
                id: 'manual',
                checked: selfSufficienceForm.selfSufficienceManually,
                value: 'Manuelle Berechnung',
                testId: 'self-sufficience-manual',
              },
              {
                id: 'automatic',
                checked: !selfSufficienceForm.selfSufficienceManually,
                value: 'Automatische Berechnung',
                testId: 'self-sufficience-automatic',
              },
            ]}
            className="memory-checkboxes"
            onChange={handleSelfSufficienceChange}
            isSingle
          />
        </div> */}
        {/* {selfSufficienceForm.selfSufficienceManually && ( */}
        <Slider
          value={selfSufficienceForm.selfSufficienceValue}
          min={30}
          max={85}
          step={1}
          testId="inflation-slider"
          onChange={(val) => photovoltaikStore.setSelfSufficienceValue(Number(val))}
          inputSuffix="%"
        />
        {/* )} */}
        {/* {!selfSufficienceForm.selfSufficienceManually && <SelfSufficienceSection />} */}
        <div className="image-upload">
          <h2 className="photovoltaik-subtitle">{i18n.t('photovoltaikPage.roofPlan').toUpperCase()}</h2>
          <div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              id="imageUpload"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <div className="upload-row">
              <Button
                text={t('basicDataPage.uploadImage')}
                onClick={handleButtonClick}
                isDisabled={!!imageRoofPlan}
                className="upload-image-button"
              />
            </div>
            {errorMessage && <p className="image-error-message">{errorMessage}</p>}
            {imageRoofPlan && (
              <div className="image-row">
                <img
                  src={imageRoofPlan.includes('blob:') ? imageRoofPlan : `${imageRoofPlan}?t=${new Date().getTime()}`}
                  alt="Selected"
                  className="selected-image"
                />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <a href={imageRoofPlan} target="_blank" rel="noopener noreferrer">
                    {imageName}
                  </a>
                  <button type="button" onClick={handleRemoveImage} className="cross-button">
                    ✖
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Tooltip isShow={nextButtonTooltip && !isValidated} text={i18n.t('validations.validateNextButton')} position="top" bottomButtons>
          <Button
            role="btnNext"
            text={i18n.t('buttons.next')}
            onClick={async () => {
              if (isValidated) {
                photovoltaikStore.syncPhotovoltaikParseData();
                goToNextPage();
              }
            }}
            isGreen
            leftMargin20
            isDisabled={!isValidated}
            testId="next-button"
            onMouseEnter={() => setNextButtonTooltip(true)}
            onMouseLeave={() => setNextButtonTooltip(false)}
          />
        </Tooltip>
      </Bottom>
    </ContentArea>
  );
});

export default PhotovoltaikPage;
